import React from "react";

import solasLogo from '../assets/images/solas_logo.png'
import tecnosealLogo from '../assets/images/tecnoseal_logo.png'
import ultraflexLogo from '../assets/images/ultraflex_logo.png'
import vsgLogo from '../assets/images/vsg_logo.png'
import cansbLogo from '../assets/images/can_sb_logo.png'
import osculatiLogo from '../assets/images/osculati_logo.png'


const Slider = () => {
  return (
    <div class="slider-container">
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img src={cansbLogo}  alt="CanSb logótipo" />
          </div>
          <div class="slide">
            <img src={osculatiLogo} alt="Osculati logótipo" />
          </div>
          <div class="slide">
            <img src={solasLogo} alt="Solas logótipo" />
          </div>
          <div class="slide">
            <img src={tecnosealLogo} alt="TecnoSeal logótipo" />
          </div>
          <div class="slide">
            <img src={ultraflexLogo} alt="UltraFlex logótipo" />
          </div>
          <div class="slide">
            <img src={vsgLogo} alt="VSG logótipo" />
          </div>
          <div class="slide">
            <img src={cansbLogo}  alt="CanSb logótipo" />
          </div>
          <div class="slide">
            <img src={osculatiLogo} alt="Osculati logótipo" />
          </div>
          <div class="slide">
            <img src={solasLogo} alt="Solas logótipo" />
          </div>
          <div class="slide">
            <img src={tecnosealLogo} alt="TecnoSeal logótipo" />
          </div>
          <div class="slide">
            <img src={ultraflexLogo} alt="UltraFlex logótipo" />
          </div>
          <div class="slide">
            <img src={vsgLogo} alt="VSG logótipo" />
          </div>
        </div>
      </div>
      <div class="slider-slogan"><p>As marcas de qualidade em que nós confiamos</p></div>
    </div>
  )
}

export default Slider