import React from "react";
import logo from '../assets/images/nautitejo_logo.png'

const Loader = () => {
  return (
    <div className="container-loader">
      <img className="loader-image" src={logo} alt="Nautitejo logótipo" />
    </div>
  )
}

export default Loader