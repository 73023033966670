import React from "react";
import Map from "./Map";

import iconphone from '../assets/images/phone_icon.svg'
import iconmail from '../assets/images/mail_icon.svg'
import iconplace from '../assets/images/map_icon.svg'

const Contacts = () => {
  return (
    <section id="contacts">
      <div class="container large">
        <div className="contacts-header">
          <div><h3 class="h1">Visite a nossa loja, temos todo o prazer recebê-lo</h3></div>
        </div>
      </div>
      <div class="container large">
        <div className="contacts">
          <div className="contacts-container-text">
            <h2>Contactos</h2>
            <div className="contacts-icons">
              <div>
                <i><img src={iconphone} alt="imagem iconografia telefone" /></i>
                <div>
                  <p>96 123 13 28</p>
                  <p>91 176 60 47</p>
                  <p>214 196 220</p>
                </div>
              </div>
              <div>
                <i><img src={iconmail} alt="imagem iconografia envelope" /></i>
                <p>geral@nautitejo.pt</p>
              </div>
              <div>
                <i><img src={iconplace} alt="imagem iconografia mapa" /></i>
                <p>Rua de Pedrouços, <br></br> 42 A 1400-285 Lisboa</p>
              </div>
            </div>
          </div>
          <Map />
        </div>
      </div>
    </section>
  )
}

export default Contacts