import React from "react";

import imgstoreWebp from '../assets/images/nautitejo_store.jpg'
import imgstorePng from '../assets/images/nautitejo_store.webp'

const About = () => {
  return (
    <section id="about" class="container">
      <div className="description">
        <div className="description-image">
          <div>
            <picture>
              <source srcSet={imgstoreWebp} type="image/webp" />
              <img src={imgstorePng}  alt="Interior loja Nautitejo" />
            </picture>
          </div>
        </div>
        <div className="description-text">
          <h2>Quem somos</h2>
          <h3>Mais de 20 Anos de experiência na indústria nautica</h3>
          <p>A Nautitejo é uma empresa inserida no sector da náutica de recreio e profissional, contando o seu fundador e colaboradores com mais de 20 anos de experiência na área.</p>
          <p>Dedicamo-nos à importação e distribuição de produtos das melhores e mais prestigiadas marcas, contando com um vasto stock nos nossos armazéns para satisfazer os nossos clientes.</p>
          <p>A nossa distribuição é feita através da nossa rede de agentes em Portugal continental e ilhas.</p>
        </div>
      </div>
    </section>
  )
}
export default About