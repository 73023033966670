import React from "react";
import Slider from "./Slider";

const Hero = () => {
  return (
    <section id="top" class="hero-container">
      <div className="hero">
        <h1 className="hero-text">Nautitejo A sua loja de produtos Nauticos</h1>
      </div>
      <Slider />
    </section>
  )
}

export default Hero