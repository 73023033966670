import React from "react";
import { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import TextSlider from "./TextSlider";

import iconarrow from '../assets/images/arrow_bottom_icon.svg'
import icondoc from '../assets/images/doc_icon.svg'
import catalogoVSG from '../assets/documents/catalogo_VSG_2021.pdf'



const Products = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <section id="products" class="container">
      <div className="products-container">
        <div className="products-container-text">
          <h2>Produtos</h2>
          <h3>Dispomos de um grande leque de produtos especializados</h3>
          <p>Sistemas de direções hidráulicas e mecânicas, produtos de segurança e manutenção, acessórios de convés, electrónica, navegação, hélices, ancoragem e amarração.</p>
        </div>
        <div>
        <Popover
          isOpen={isPopoverOpen}
          positions={['top', 'right', 'left', 'bottom']}
          padding={10}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'white'}
              arrowSize={15}
              className='popover-arrow-container'
              arrowClassName='popover-arrow'
            >
              <div
                className="popover-content"
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              >
                <p><a href="https://www.ultraflex.it/wp-content/uploads/2021/09/ULTRAFLEX_2022-ENG.pdf" target="_blank"  rel="noreferrer">Ultraflex</a><i><img src={iconarrow} alt="imagem seta download" /></i></p>
                <p><a href={catalogoVSG} target="_blank"  rel="noreferrer">VSG</a><i><img src={iconarrow} alt="imagem seta download" /></i></p>
                <p><a href="https://www.tecnoseal.co.uk/tecnoseal-anode-catalogue-2022/" target="_blank"  rel="noreferrer">Tecnoseal</a><i><img src={iconarrow} alt="imagem seta download" /></i></p>
                <p><a href="https://www.osculati.com/en/page/catalogues" target="_blank"  rel="noreferrer">Osculati</a><i><img src={iconarrow} alt="imagem seta download" /></i></p>
                <p><a href="https://cansb.com/download/CanSB-Cat-2022.pdf" target="_blank"  rel="noreferrer">CANSB</a><i><img src={iconarrow} alt="imagem seta download" /></i></p>
                <p><a href="https://www.rubexprops.com/pdf/2022_Solas-Propellers-Catalog.pdf" target="_blank"  rel="noreferrer">Solas</a><i><img src={iconarrow} alt="imagem seta download" /></i></p>
              </div>
            </ArrowContainer>
          )}
        >
            <span className="btn" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
              <p>Download de catálogos</p><i><img src={icondoc} alt="imagem iconografia documento" /></i>
            </span>
        </Popover>
        </div>
      </div>
      <TextSlider />
    </section>
  )
}

export default Products