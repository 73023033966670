import React from "react";
import { Link } from "react-scroll";

import logo from '../assets/images/nautitejo_logo.png'

(function getScroll(){
  let navbar = document.getElementsByClassName('header-nav');
  window.addEventListener('scroll',function(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if(scrollTop > 0){
          navbar[0].classList.add("navScroll")
      }
      else{
          navbar[0].classList.remove("navScroll")
      }
  })
})()

const Header = () => {
  return (
    <>
    <span id="top"></span>
    <nav className="header-nav">
      <Link href="#top" className="header-nav-logo" smooth spy to="top"><img src={logo} alt="Nautitejo logótipo" /></Link>
      <ul className="header-nav-navigation">
        <li><Link href="#about" activeClass="active" smooth spy to="about">Quem Somos</Link></li>
        <li><Link href="#products" activeClass="active" smooth spy to="products">Produtos</Link></li>
        <li><Link href="#contacts" activeClass="active" smooth spy to="contacts">Contactos</Link></li>
      </ul>
    </nav>
    </>
  )
}

export default Header