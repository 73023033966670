import React from "react";

const Map = () => {
  return (
    <>
      <iframe width="100%" height="500px" title="Mapa da loja Nautitejo" frameborder="0" allowfullscreen src="//umap.openstreetmap.fr/pt-pt/map/clone-de-nautitejo-mapa-site_540841?scaleControl=false&miniMap=false&scrollWheelZoom=false&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false"></iframe>
    </>
  )
}

export default Map