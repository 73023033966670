import { useEffect, useState } from 'react';

import './App.css';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Products from './components/Products';
import Loader from './components/Loader';

function App() {
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 4000);
  }, []);

  return (

      isDisplayed ?
      <>
      <Header />
      <main>
        <Hero />
        <About />
        <Products />
        <Contacts />
      </main>
      <Footer />
      </> :
      <Loader />

  )
}

export default App;
