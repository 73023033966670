import React from "react";

const Footer = () =>  {
  return (
    <footer className="footer">
      <div>
        <p>© Nautitejo 2022 todos os direitos reservados</p>
      </div>
    </footer>
  )
}

export default Footer