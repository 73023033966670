import React from "react";

const TextSlider = () => {
  return (
    <div className="text-slider-container">
      <div className="text-slider">
        <p class="text-slide">
          <span>direções</span><span>segurança e manutenção</span><span>acessórios de convés</span><span>electrónica</span>
          <span>navegação</span><span>hélices</span><span>ancoragem</span><span>amarração</span>
        </p>
        <p class="text-slide">
          <span>direções</span><span>segurança e manutenção</span><span>acessórios de convés</span><span>electrónica</span>
          <span>navegação</span><span>hélices</span><span>ancoragem</span><span>amarração</span>
        </p>
      </div>
    </div>
  )
}

export default TextSlider